<template>
	<div>
		<div style='min-width: 150rem;padding: 12rem 24rem 0;font-size: 22rem'>1. 学生信息同步</div>
		<el-row style='color: red;font-size: 14rem'>
			<el-col :span='12' style='padding: 0 24rem;margin-top: 12rem;margin-bottom: -12rem'>
				*同步学生基础信息及分班,本周课表
			</el-col>
		</el-row>
		<el-row>
			<el-col :span='12' style='padding: 24rem'>
				<el-row style='display: flex;align-items: center'>
					<el-col :span='4' style='width: 217rem'>
						<el-input v-model='studentNo' placeholder='请输入学生学号'></el-input>
					</el-col>
					<el-col :span='3' style='margin-left: 24rem'>
						<el-button @click='onSynchronize(1)' :disabled='!studentNo'>确认同步</el-button>
					</el-col>
				</el-row>
			</el-col>
		</el-row>

		<div style='min-width: 150rem;padding: 12rem 24rem 0;font-size: 22rem'>2. 教师信息同步</div>
		<el-row style='color: red;font-size: 14rem'>
			<el-col :span='12' style='padding: 0 24rem;margin-top: 12rem;margin-bottom: -12rem'>
				*同步教师教学班班级及本周课表更新
			</el-col>
		</el-row>
		<el-row>
			<el-col :span='12' style='padding: 24rem'>
				<el-row style='display: flex;align-items: center'>

					<el-col :span='4' style='width: 217rem'>
						<el-input v-model='username' placeholder='请输入教师账号'></el-input>
					</el-col>
					<el-col :span='3' style='margin-left: 24rem'>
						<el-button @click='onSynchronize(2)' :disabled='!username'>确认同步</el-button>
					</el-col>
				</el-row>
			</el-col>
		</el-row>

		<div style='min-width: 150rem;padding: 12rem 24rem 0;font-size: 22rem'>3. 考试后统一分班</div>
		<el-row style='color: red;font-size: 14rem'>
			<el-col :span='12' style='padding: 0 24rem;margin-top: 12rem;margin-bottom: -12rem'>
				*统一考试之后对应校区,年级下的学生课表更新
			</el-col>
		</el-row>
		<el-row style='padding: 24rem;display: flex;align-items: center;'>
			<el-col :span='4' style='width: 243rem'>
				<el-select v-model='data.school_id' placeholder='请选择校区'>
					<el-option
              v-for='(item,key) of school_arr'
              :key='key'
              :label='item'
              :value='key'>
					</el-option>
				</el-select>
			</el-col>
			<el-col :span='4' style='width: 243rem'>
				<el-select v-model='data.grade_id' placeholder='请选择年级'>
					<el-option
              v-for='item in grade_arr'
              :key='item.id'
              :label='item.name'
              :value='item.id'>
					</el-option>
				</el-select>
			</el-col>
			<el-col :span='4'>
				<el-button @click='onSynchronize(3)' :disabled='!data.school_id || !data.grade_id'>确认同步</el-button>
			</el-col>
		</el-row>

    <div style='min-width: 150rem;padding: 12rem 24rem 0;font-size: 22rem'>4. 课表同步</div>
		<el-row style='color: red;font-size: 14rem'>
			<el-col :span='12' style='padding: 0 24rem;margin-top: 12rem;margin-bottom: -12rem'>
				*课表同步选择条件下的课表更新
			</el-col>
		</el-row>
		<el-row style='padding: 24rem;display: flex;align-items: center;'>
			<el-col :span='4' style='width: 243rem'>
				<el-select v-model='schoolTimetable.school_id' placeholder='请选择校区'>
					<el-option
              v-for='(item,key) of school_arr'
              :key='key'
              :label='item'
              :value='key'>
					</el-option>
				</el-select>
			</el-col>
			<el-col :span='4' style='width: 243rem'>
				<el-select v-model='schoolTimetable.grade_id' placeholder='请选择年级'>
					<el-option
              v-for='item in grade_arr'
              :key='item.id'
              :label='item.name'
              :value='item.id'>
					</el-option>
				</el-select>
			</el-col>
      <el-col :span='4' style='width: 243rem'>
				<el-select v-model='schoolTimetable.user_type' placeholder='请选择用户类型'>
					<el-option key='1' label='学生' value='1'></el-option>
					<el-option key='2' label='教师' value='2'></el-option>
					<el-option key='3' label='所有' value='3'></el-option>
				</el-select>
			</el-col>
      <el-col :span='4' style='width: 243rem'>
				<el-input v-model='schoolTimetable.update_reason' maxlength="30" placeholder='同步课程的原因(限30字)'></el-input>
			</el-col>
			<el-col :span='4'>
				<el-button @click='onSynchronize(4)' :disabled='!schoolTimetable.school_id || !schoolTimetable.grade_id || !schoolTimetable.user_type || !schoolTimetable.update_reason' style='margin-left: 24rem'>确认同步</el-button>
			</el-col>
		</el-row>

    <div style='min-width: 150rem;padding: 12rem 24rem 0;font-size: 22rem'>5. 同步信息到班牌</div>
    <el-row style='color: red;font-size: 14rem'>
      <el-col :span='12' style='padding: 0 24rem;margin-top: 12rem;margin-bottom: 12rem'>
        *同步信息到班牌
      </el-col>
    </el-row>
    <el-row style='color: red;font-size: 14rem'>
      <el-col :span='4' style='padding: 0 24rem;margin-top: 12rem;margin-bottom: -12rem'>
        <el-select v-model='str' placeholder='请选择' style="width: 217rem" @change=onChangeStr filterable>
          <el-option v-for='item in formConf' :key='item.function_en' :label='item.function_cn' :value='item.function_en'></el-option>
        </el-select>
        <div>{{title.title}}</div>
      </el-col>
    </el-row>
    <div style='color: red;font-size: 14rem;padding: 0 24rem;margin-bottom: -12rem;margin-top: 24rem;display: flex'>
      <div v-for="(item, index) in html_params">
        <div v-if="item.html_type === 'input' && item.is_display === 1" style="width: 217rem; margin-right: 24rem">
          <el-input :is_disabled="item.is_disabled === 1" :name="item.field_name" :placeholder="item.placeholder || '请输入'" :value="html_params[index].default_value" style='width: 217rem;margin-bottom: 24rem;margin-right: 24rem' @input="change($event,index)"></el-input>
          <div style="font-size: 10rem">{{item.title}}</div>
        </div>

        <div v-if="item.html_type === 'input_date' && item.is_display === 1" style="width: 217rem; margin-right: 24rem">
          <el-date-picker v-model="html_params[index].default_value" :placeholder="item.placeholder || '请选择日期'" style="margin-bottom: 24rem;margin-right: 24rem" type="date" value-format="yyyy-MM-dd"></el-date-picker>
          <div style="font-size: 10rem">{{item.title}}</div>
        </div>

        <div v-if="item.html_type === 'input_datetime' && item.is_display === 1" style="width: 217rem; margin-right: 24rem">
          <el-date-picker v-model="html_params[index].default_value" :placeholder="item.placeholder || '请选择日期'" style="margin-bottom: 24rem;margin-right: 24rem" type="datetime" value-format="yyyy-MM-dd"></el-date-picker>
          <div style="font-size: 10rem">{{item.title}}</div>
        </div>

        <div v-if="item.html_type === 'input_time' && item.is_display === 1" style="width: 217rem">
          <el-time-select value-format="yyyy-MM-dd" v-model="html_params[index].default_value" :picker-options="{start: '00:00', step: '00:01', end: '24:59' }" :placeholder="item.placeholder || '请选择时间'" style="margin-bottom: 24rem;margin-right: 24rem"></el-time-select>
          <div style="font-size: 10rem">{{item.title}}</div>
        </div>

        <div v-if="item.html_type === 'input_select' && item.is_display === 1" style="width: 217rem">
          <el-select v-model="html_params[index].default_value" :placeholder="item.placeholder" filterable style="margin-bottom: 24rem;margin-right: 24rem">
            <el-option v-for="(item_, index_) of item.select_data_list" :key="item.select_data_list[item_]" :label="item_" :value="index_" ></el-option>
          </el-select>
          <div style="font-size: 10rem">{{item.title}}</div>
        </div>

        <div v-if="item.html_type === 'input_select_one' && item.is_display === 1" style="width: 217rem">
          <el-select v-model="html_params[index].default_value" :placeholder="item.placeholder" filterable style="margin-bottom: 24rem;margin-right: 24rem">
            <el-option v-for="(item_, index_) of item.select_data_list" :key="item.select_data_list[item_]" :label="item_" :value="index_" ></el-option>
          </el-select>
          <div style="font-size: 10rem">{{item.title}}</div>
        </div>

        <div v-if="item.html_type === 'input_select_multible' && item.is_display === 1" style="width: 217rem">
          <el-select v-model="html_params[index].default_value" :placeholder="item.placeholder" filterable multiple style="margin-bottom: 24rem;margin-right: 24rem">
            <el-option v-for="(item_, index_) of item.select_data_list" :key="item.select_data_list[item_]" :label="item_" :value="index_" ></el-option>
          </el-select>
          <div style="font-size: 10rem">{{item.title}}</div>
        </div>

        <div v-if="item.html_type === 'select_one' && item.is_display === 1" style="width: 217rem">
          <el-select v-model="html_params[index].default_value" :placeholder="item.placeholder" style="margin-bottom: 24rem;margin-right: 24rem">
            <el-option v-for="(item_, index_) of item.select_data_list" :key="item.select_data_list[item_]" :label="item_" :value="index_" ></el-option>
          </el-select>
          <div style="font-size: 10rem">{{item.title}}</div>
        </div>

        <div v-if="item.html_type === 'select_multible' && item.is_display === 1" style="width: 217rem">
          <el-select v-model="html_params[index].default_value" :placeholder="item.placeholder" multiple style="margin-bottom: 24rem;margin-right: 24rem">
            <el-option v-for="(item_, index_) of item.select_data_list" :key="item.select_data_list[item_]" :label="item_" :value="index_" ></el-option>
          </el-select>
          <div style="font-size: 10rem">{{item.title}}</div>
        </div>
      </div>
      <el-button @click='onSynchronize(5)' style="height: 40rem" v-if="html_params.length">确认同步</el-button>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="()=>dialogVisible = false">
      <span>{{ title.submit_prompt }}</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onConfirm">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  data() {
    return {
      studentNo: '',
      username: '',
      data: {
        school_id: '',
        grade_id: ''
      },
      school_arr: {},
      grade_arr: [],
      schoolTimetable: {},
      formConf: [],
      html_params: [],
      str: '',
      title: {},
      dialogVisible: false,
    };
  },
  methods: {
    onSynchronize(val) {
      if (val === 1) {
        this.$_axios2.post('api/common/synchro/student', { studentNo: this.studentNo }).then(res => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
      if (val === 2) {
        this.$_axios2.post('api/common/synchro/teacher', { username: this.username }).then(res => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
      if (val === 3) {
        this.$_axios2.post('api/common/synchro/exam-shift', this.data).then(res => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg);
          }
        });
      }
      if (val === 4) {
        this.$_axios2.post('/api/common/synchro/calendar', this.schoolTimetable).then(res => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg);
          }
        });
      }
      if (val === 5) {
        if (this.title.submit_prompt) {
          return this.dialogVisible = true
        }
        let obj = {}
        this.html_params.forEach(item=>{
          if (item.is_disabled === 0){
            obj[item.field_name] = item.default_value
          }
        })
        this.$_axios2.post('api/common/synchro/hikvison-form-data', obj).then(res=>{
          if (res.data.status === 0) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        })
      }
    },
    onChangeStr(val) {
      this.str = val
      for (let i = this.formConf.length - 1; i >= 0; i--) {
        if (this.formConf[i].function_en === val){
          this.html_params = this.formConf[i].html_params
          this.title = this.formConf[i]
        }
      }
    },
    change(e,index){
      this.html_params[index].default_value = e;
    },
    onConfirm(){
      let obj = {}
      this.html_params.forEach(item=>{
        if (item.is_disabled === 0){
          obj[item.field_name] = item.default_value
        }
      })
      this.$_axios2.post('api/common/synchro/hikvison-form-data', obj).then(res=>{
        if (res.data.status === 0) {
          this.$message.success(res.data.msg);
          this.dialogVisible = false
        } else {
          this.$message.error(res.data.msg);
        }
      })
    }
  },
  created() {
    this.school_arr = JSON.parse(sessionStorage.getItem('USER_INFO')).school_arr;
    this.$_axios.get('site/grades').then(res => {
      this.grade_arr = res.data.data;
    });
    this.$_axios2.get('api/common/synchro/hikvison-form-conf').then(res => {
      this.formConf = res.data.data
      console.log(this.formConf)
    });
  }
};
</script>

<style scoped>

</style>
